<template>
  <el-tabs
    v-model="activeTab"
    type="card"
    class="education-content tabs-full"
    @tab-click="toggleEducationTab"
  >
    <el-tab-pane label="商品主图" name="goodsImg" v-if="isDecoration">
      <GoodsImgScore ref="goodsImg"></GoodsImgScore>
    </el-tab-pane>
    <el-tab-pane label="店内分类" name="shopCategory" v-if="isDecoration">
      <ShopCategoryScore ref="shopCate"></ShopCategoryScore>
    </el-tab-pane>
    <el-tab-pane label="电脑海报" name="pcBanner" v-if="isDecoration">
      <PcBannerScore ref="pcBanner"></PcBannerScore>
    </el-tab-pane>
    <el-tab-pane label="店招" name="storeBanner" v-if="isDecoration">
      <StoreBannerScore ref="storeBanner"></StoreBannerScore>
    </el-tab-pane>
    <el-tab-pane label="分析答辩" name="analysisAnswer" v-if="isLuyan">
      <AnalysisAnswerScore ref="analysisAnswer"></AnalysisAnswerScore>
    </el-tab-pane>
    <el-tab-pane label="问答评分" name="questionScore" v-if="isTheory">
      <AnswerScore ref="questionScore"></AnswerScore>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import AnalysisAnswerScore from "@/components/teacher/AnalysisAnswerScore";
import GoodsImgScore from "@/components/teacher/GoodsImgScore";
import PcBannerScore from "@/components/teacher/PcBannerScore";
import ShopCategoryScore from "@/components/teacher/ShopCategoryScore";
import StoreBannerScore from "@/components/teacher/StoreBannerScore";
import AnswerScore from "@/components/teacher/AnswerScore.vue";
import {mapGetters} from "vuex";

export default {
  name: "Index",
  data() {
    return {
      activeTab: "goodsImg",
      competitionId: 0,

    };
  },
  mounted() {
    this.tabItemList();
  },
  created() {
    //查看缓存的tab
    let active_tab = this.$lockr.get("active-tab");
    if (active_tab) {
      this.activeTab = active_tab;
    } else {
        if (this.operationM.indexOf(5) !== -1) {
            this.activeTab = "questionScore"
        }
        if (this.operationM.indexOf(4) !== -1) {
            this.activeTab = "analysisAnswer"
        }
        if (this.operationM.indexOf(1) !== -1) {
            this.activeTab = "goodsImg"
        }
    }
    if (this.$route.query.id) {
      this.competitionId = Number(this.$route.query.id);
    }
  },
    watch: {
      isShow (newVal, oldVal) {
        if (newVal.indexOf(5) !== -1) {
          this.activeTab = "questionScore"
        }
        if (newVal.indexOf(4) !== -1) {
          this.activeTab = "analysisAnswer"
        }
        if (newVal.indexOf(1) !== -1) {
          this.activeTab = "goodsImg"
        }
        this.tabItemList()
      },
      $route(to, from) {
        this.competitionId = Number(to.query.id);
        this.tabItemList();
      },
    },
    computed: {
      ...mapGetters([
        "operationM"
      ]),
      isShow () {
        return this.operationM;　　//需要监听的数据
      },
      //装修：1  运营：2  客服：3  路演： 4  理论：5  客服分析：6  新客服：7
      isDecoration() {
        if (this.operationM.indexOf(1) !== -1) {
          return true
        }
      },
      isLuyan() {
        if (this.operationM.indexOf(4) !== -1) {
          return true
        }
      },
      isTheory() {
        if (this.operationM.indexOf(5) !== -1) {
          return true
        }
        }
    },
  components: {
    AnalysisAnswerScore,
    GoodsImgScore,
    PcBannerScore,
    ShopCategoryScore,
    StoreBannerScore,
    AnswerScore,
  },

  methods: {
    toggleEducationTab(tab) {
      this.$lockr.set("active-tab", this.activeTab);
      this.tabItemList();
    },
    tabItemList() {
      switch (this.activeTab) {
        case "goodsImg":
          this.$refs.goodsImg.getStuList();
          break;
        case "shopCategory":
          this.$refs.shopCate.getStuList();
          break;
        case "pcBanner":
          this.$refs.pcBanner.getStuList();
          break;
        case "storeBanner":
          this.$refs.storeBanner.getStuList();
          break;
        case "analysisAnswer":
          this.$refs.analysisAnswer.getStuList();
          break;
        case "questionScore":
          this.$refs.questionScore.showScoreListData();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.education-content {
  height: 100%;
}
</style>