<template>
  <div class="analysis-answer-content">
    <div class="pw-score-main">
      <el-table
        :data="data"
        style="width: 100%"
        height="100%"
        header-cell-class-name="pw-table-header"
      >
        <el-table-column
          prop="competition_name"
          label="竞赛名称"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="username"
          label="工位号"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column prop="" label="答案" align="center">
          <template slot-scope="scope">
            <div class="pw-store-banner">
              <div class="ppt-item" v-if="scope.row.answer">
                <a
                  :href="scope.row.answer.file_path"
                  tag="a"
                  class="file-download"
                  target="_blank"
                >
                  <i class="iconfont" v-html="fileExtIcon(scope.row)"></i
                  ><span>{{ scope.row.answer.file_name }}</span>
                </a>
              </div>
              <span v-else>该学生未提交答案</span>
            </div>
          </template>
        </el-table-column>
      <el-table-column
          prop=""
          :label="`评分`"
          width="200"
          align="center"
      >
<!--        <el-table-column-->
<!--          prop=""-->
<!--          :label="`评分（分值最高为${totalScore}分）`"-->
<!--          width="200"-->
<!--          align="center"-->
<!--        >-->

          <template slot-scope="scope">
            <div class="pf-act-content">
              <el-input-number
                v-model="scope.row.score"
                controls-position="right"
                class="pw-input"
                :min="0"
                :max="totalScore"
              ></el-input-number>
              <el-button
                type="primary"
                size="small"
                class="pw-confirm-btn"
                @click="confirmScore(scope.row)"
                >确定</el-button
              >
              <!--<template v-if="isScoreList[scope.row.s_id]">
                            </template>
                            <template v-else>
                                <span>{{scope.row.score}}分</span>
                            </template>-->
            </div>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <a
              :href="
                scope.row.answer
                  ? scope.row.answer.file_path
                  : 'javascript:void(0);'
              "
              class="file-download"
              target="_blank"
              v-if="scope.row.answer"
              >查看详情</a
            >
            <span v-else>查看详情</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pw-page-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        :page-size.sync="limit"
        @current-change="changePage"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import _ from "underscore";

export default {
  name: "AnalysisAnswerScore",
  data() {
    return {
      currentPage: 1,
      total: 10,
      data: [],
      totalScore: 0,
      isScoreList: [],
      limit: 10,
    };
  },
  created() {
    this.getStuList();
  },
  methods: {
      fileExtIcon(row) {
          let fileExts = row.answer.file_name.split(".")
          const jsonExt = {
              "mp4": "&#xe675;",
              "ppt": "&#xe63e;",
              "pptx": "&#xe63e;"
          }
          return jsonExt[fileExts[fileExts.length - 1]]
      },
    getStuList() {
      let param = {
        page: this.currentPage,
        limit: this.limit,
        type: 5,
        competition_id: this.$route.query.id,
      };
      this.$http.axiosGetBy(
        this.$api.answerList,
        param,
        (res) => {
          if (res.code === 200) {
            this.data = res.data.Answer;
            this.totalScore = res.data.model_score;
            this.total = res.data.stu_total;
            _.each(this.data, (item) => {
              if (item.score === null) {
                this.isScoreList[item.s_id] = true;
              } else {
                this.isScoreList[item.s_id] = false;
              }
            });
          } else {
            this.data = [];
          }
        },
        (error) => {
          console.log(error);
          this.$common.axiosErrorMsg(error);
        }
      );
    },
    changePage(val) {
      this.currentPage = val;
      this.getStuList();
    },
    confirmScore(value) {
      let data = {
        competition_id: this.$route.query.id,
        result_type: 5,
        user_id: value.s_id,
        score: value.score,
      };
      this.$http.axiosPost(
        this.$api.pwScore,
        data,
        (res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getStuList();
          }else{
               this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        },
        (err) => {
          this.$common.axiosErrorMsg(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-answer-content {
  padding: 30px 30px 0 30px;
  height: calc(100% - 30px);
  /deep/ .pw-table-header {
    background-color: #f3f4fa;
    color: #14141c;
    font-size: 14px;
  }
  .pw-store-banner {
    height: 80px;
    width: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    .ppt-item {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      a.file-download {
        color: #333;
        &:hover {
          color: #666;
          span {
            text-decoration: underline;
          }
        }
      }
      i.iconfont {
        font-size: 24px;
        color: #c84724;
      }
      span {
        padding-left: 5px;
        font-size: 16px;
      }
    }
  }
  .pf-act-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .pw-input {
      width: 100px;
      margin-right: 8px;
    }
    .pw-confirm-btn {
      background-color: #1222d8;
      border-color: #1222d8;
    }
  }
}
</style>